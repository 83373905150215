
import { Component, Vue } from 'vue-property-decorator';
import workspaceModule from '@/store/modules/workspaceModule';
import { getComponent } from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
	WorkflowAutomationDialog: () => getComponent('tasqs/WorkflowAutomationDialog'),
	Multiselect,
  },
})
export default class WorkspacesCreateNewTableModal extends Vue {


  fieldSelection = ''
  fieldNameSelection = ''

  dataLoading = false

  selectedAddView = null

  addNewWorkspacePopup = false

  addNewWorkspacePopupAddField = false

  templateDetailsFieldIndexExpanded = []

  templates: any[] = []

  templateDetails = [
	  {
		  type: "Fields",
		  values: [
	
		  ]
	  },{
		  type: "Rules",
		  values: [
			  
		  ]
	  }
  ]



	windowWidth = window.innerWidth;

	get isMobile() {
		return this.windowWidth < 768;
	}


	dateToYMD(date) {
		var strArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		var d = date.getDate();
		var m = strArray[date.getMonth()];
		var y = date.getFullYear();
		return m + ', ' + (d <= 9 ? '0' + d : d) + ' ' + y;
	}

  closeModal() {
	  this.$emit("close-add-new-table-popup")
  }

  get fieldSelectionOptions() {
	  var results: any[] = []
	  for (var y = 0; y < scheduleModule.columnMapping.length; y++) {
		  if (scheduleModule.columnMapping[y].Enabled) {
			  results.push(scheduleModule.columnMapping[y].ColumnName)
		  }
		  
	  }
	  return results
  }

  
	get templatePageTemplateType() {
		// @ts-ignore
		return this.selectedAddView.use == "TEMPLATE" ? "Template" : "Start from scratch"
	}

	templatePageTitle = ''



	get templatePageDateCreated() {
		// @ts-ignore
		return this.dateToYMD(new Date(this.selectedAddView.date_created))
	}

	get templatePageDescriptionSectionDetails() {
		// @ts-ignore
		return this.selectedAddView.description
	}

	get templatePageImageURL() {
		// @ts-ignore
		return this.selectedAddView.url
	}




	getRuleHtml(rule) {
		var when_rule_html = '<p style="float: left; clear: both;">When '
		var then_rule_html = '<p style="float: left; margin-top: 6px; margin-left: 0px; clear: both;">Then '
		var rule_json = rule.RuleJson
		var when_rule = rule_json.when
		var then_rule = rule_json.then

		for (var x = 0; x < when_rule.rule_json.length; x++) {
			if (x > 0) {
				when_rule_html = when_rule_html + " and "
			}
			if ("Status Changes" == when_rule.rule_json[x].type.value) {
				when_rule_html = when_rule_html + ' <span style="font-weight: 500; text-decoration: underline;">' + when_rule.rule_json[x].type.value + "</span> from "
				for (var y = 0; y < when_rule.rule_json[x].details.length; y++) {
					var details_html = ''
					for (var z = 0; z < when_rule.rule_json[x].details[y].response.length; z++) {
						if (z > 0) {
							details_html = details_html + ", "
						}
						details_html = details_html + '<span style="font-weight: 500; text-decoration: underline;">' + when_rule.rule_json[x].details[y].response[z].text + '</span>'
					}
					if (when_rule.rule_json[x].details[y].response.length == 0) {
						when_rule_html = when_rule_html + ' <span style="font-weight: 500; text-decoration: underline;">Any</span> '
					}
					if (y == 0) {
						when_rule_html = when_rule_html + details_html
					} else {
						when_rule_html = when_rule_html + " to " + details_html
					}
				}
			}
		}

		when_rule_html = when_rule_html + '</p>'

		for (var x = 0; x < then_rule.rule_json.length; x++) {
			if (x > 0) {
				then_rule_html = then_rule_html + " and "
			}
			if ("Duplicate Tasq" == then_rule.rule_json[x].type.value) {
				then_rule_html = then_rule_html + '<span style="font-weight: 500; text-decoration: underline;">' + then_rule.rule_json[x].type.value + '</span> '
				for (var y = 0; y < then_rule.rule_json[x].details.length; y++) {
					var details_html = ''
					for (var z = 0; z < then_rule.rule_json[x].details[y].response.length; z++) {
						if (z > 0) {
							details_html = details_html + ", "
						}
						details_html = details_html + '<span style="font-weight: 500; text-decoration: underline;">' + then_rule.rule_json[x].details[y].response[z].text + '</span> '
					}
					if (y == 0) {
						then_rule_html = then_rule_html + details_html
					} else {
						then_rule_html = then_rule_html + details_html
					}
				}
			}
		}
		then_rule_html = then_rule_html + '</p>'
		var next_arrow_html = '<span \
							style="clear: both; color: rgba(255,255,255,0.66); font-size: 24px; margin-top: 5px; margin-bottom: 2px; float: left;" \
							class="material-icons-outlined" \
							> \
							arrow_downward \
							</span>'
		return when_rule_html + next_arrow_html+ then_rule_html

	}



  applyNewWorkspacePopupAddField() {
	if (this.fieldSelection != '' && this.fieldNameSelection != '') {
		var local_icon = ''
		var local_value = ''
		var icon_type = 'MATERIAL'
		if (this.fieldSelection == "Tasq Date Range") {
			local_icon = "date_range"
			local_value = "DATE"
		} else if (this.fieldSelection == "Well") {
			local_value = "WELL"
			icon_type = "WELL"
		} else if (this.fieldSelection == "Due Date") {
			local_value = "event"
			icon_type = "DUE_DATE"
		} else if (this.fieldSelection == "Start Date") {
			local_value = "event"
			icon_type = "START_DATE"
		} else if (this.fieldSelection == "User") {
			local_icon = "person"
			local_value = "USER"
		} else if (this.fieldSelection == "Vendor") {
			local_icon = "engineering"
			local_value = "VENDOR"
		} else if (this.fieldSelection == "Cost") {
			local_icon = "attach_money"
			local_value = "COST"
		} else if (this.fieldSelection == "Tags") {
			local_icon = "tag"
			local_value = "TAGS"
		} else if (this.fieldSelection == "Text") {
			local_icon = "format_size"
			local_value = "TEXT"
		} else if (this.fieldSelection == "Date") {
			local_icon = "event"
			local_value = "DATE"
		} else if (this.fieldSelection == "File") {
			local_icon = "picture_as_pdf"
			local_value = "FILE"
		} else if (this.fieldSelection == "Checklist") {
			local_icon = "checklist"
			local_value = "CHECKLIST"
		} else if (this.fieldSelection == "Procedure") {
			local_icon = "task"
			local_value = "PROCEDURE"
		} else if (this.fieldSelection == "Email") {
			local_icon = "email"
			local_value = "EMAIL"
		} else if (this.fieldSelection == "Phone Number") {
			local_icon = "phone"
			local_value = "PHONE_NUMBER"
		} else if (this.fieldSelection == "Number") {
			local_icon = "numbers"
			local_value = "NUMBER"
		} else if (this.fieldSelection == "Description") {
			local_icon = "toc"
			local_value = "DESCRIPTION"
		} else if (this.fieldSelection == "Title") {
			local_icon = "title"
			local_value = "TITLE"
		} else if (this.fieldSelection == "Status") {
			local_icon = "assignment_turned_in"
			local_value = "STATUS"
		}

		
		this.templateDetails[0].values.push({
			// @ts-ignore
			title: this.fieldSelection,
			// @ts-ignore
			icon: local_icon,
			// @ts-ignore
			icon_type: icon_type,
			// @ts-ignore
			column_type: local_value,
			// @ts-ignore
			custom_name: this.fieldNameSelection,
		})
		this.addNewWorkspacePopupAddField = false
		if (!this.getTemplateDetailsFieldIndexExpanded(0)) {
			this.setTemplateDetailsFieldIndexExpandedIcon(0)
		}
	}
	this.fieldSelection = ''
	this.fieldNameSelection = ''
  }



	removeField(index) {
		this.templateDetails[0].values.splice(index, 1);
	}

  setTemplateDetailsFieldIndexExpandedIcon(indexPassed) {
	  // @ts-ignore
	  if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
		  // @ts-ignore
		const index = this.templateDetailsFieldIndexExpanded.indexOf(indexPassed);
		if (index > -1) {
			this.templateDetailsFieldIndexExpanded.splice(index, 1);
		}
	  } else {
		  // @ts-ignore
		  this.templateDetailsFieldIndexExpanded.push(indexPassed)
	  }
  }


  getTemplateDetailsFieldIndexExpanded(indexPassed) {
	  // @ts-ignore
	  if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
		  return true
	  }
	  return false
  }


  getTemplateDetailsFieldIndexExpandedIcon(indexPassed) {
	  // @ts-ignore
	  if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
		  return "expand_more"
	  }
	  return "chevron_right"
  }

  get workspaces(): any[] {
	  return workspaceModule.navBarOptions
  }



  async createNewTable() {
	this.dataLoading = true


	await scheduleModule.postNewWorkspaceTable({
		username: accountModule.user.email.toLowerCase(),
		// @ts-ignore
		workspace_id: workspaceModule.activeWorkspace.ID,
		table_title: this.templatePageTitle
	})


	var columns: any = []
	for (var r = 0; r < this.templateDetails[0].values.length; r++) {
		var didFindColumn = false
		for (var f = 0; f < scheduleModule.columnMapping.length; f++) {
			// @ts-ignore
			if (this.templateDetails[0].values[r].column_type == scheduleModule.columnMapping[f].ColumnType) {
				didFindColumn = true
				var columnMappingCopy = JSON.parse(JSON.stringify(scheduleModule.columnMapping[f]))
				// @ts-ignore
				columnMappingCopy.CoreField = this.templateDetails[0].values[r].required
				// @ts-ignore
				columnMappingCopy.RequiredOnJobCreation = this.templateDetails[0].values[r].requiredOnJobCreation
				columns.push({
					...columnMappingCopy,
					// @ts-ignore
					CustomName: this.templateDetails[0].values[r].custom_name
				})
				break
			}
		}
		if (!didFindColumn) {
			console.log("Couldn't find column")
			// console.log(this.templateDetails[0].values[r])
		}
		
	}

	
	  await scheduleModule.postNewTableGroup({
		  username: accountModule.user.email.toLowerCase(),
		  // @ts-ignore
		  workspace_id: workspaceModule.activeWorkspace.ID,
		  table_id: scheduleModule.recentlyPostedTableID,
		  group_name: "Row 1",
		  columns: JSON.stringify(columns),

	  })

	for (var x = 0; x < this.templateDetails[1].values.length; x++) {
		await scheduleModule.postNewRule({
				// @ts-ignore
			workspace_id: workspaceModule.activeWorkspace.ID,
				// @ts-ignore
			table_id: scheduleModule.recentlyPostedTableID,
			// @ts-ignore
			rule_json: JSON.stringify(this.templateDetails[1].values[x].RuleJson),
		})
	}


	  this.dataLoading = false
	  this.$emit("close-add-new-table-popup")
	  window.location.reload();
	//     postNewTableGroup({
	// username,
	// workspace_id,
	// table_id,
	// group_name,
	// columns
	// }

	// postNewWorkspaceTable({
	// 	username,
	// 	workspace_id,
	// 	}
  }

  async created() {
	  
	  this.dataLoading = true
	await scheduleModule.getScheduleTemplates({})
    scheduleModule.getColumnMappingData({})
	this.templates = []
	for (const [key, value] of Object.entries(scheduleModule.scheduleTemplates)) {
		// var parsed_value = JSON.parse(value)
		this.templates.push({
			id: key,
			name: value.Name,
			description: value.Description,
			url: value.Image,
			use: value.Use,
			groups: value.Groups,
			rules: value.Rules,
			date_created: value.DateCreated
		})
	}
	this.dataLoading = false



  }

  selectAddViewOptionBack() {
	  // @ts-ignore
	  this.selectedAddView = null
  }


  getColumnTypeIcon(column_type) {
	  if (column_type == "WELL") {
		  return 
	  } else if (column_type == "USER") {
		  return "person"
	  } else if (column_type == "VENDOR") {
		  return "engineering"
	  } else if (column_type == "CHECKLIST") {
		  return "checklist"
	  } else if (column_type == "FILE") {
		  return "picture_as_pdf"
	  } else if (column_type == "TESTERS") {
		  return "quiz"
	  } else if (column_type == "TASQ_DATE_RANGE") {
		  return "date_range"
	  } else if (column_type == "COST") {
		  return "attach_money"
	  } else if (column_type == "TAGS") {
		  return "tag"
	  } else if (column_type == "TEXT") {
		  return "format_size"
	  } else if (column_type == "DATE") {
		  return "calendar"
	  } else if (column_type == "PROCEDURE") {
		  return "task"
	  } else if (column_type == "EMAIL") {
		  return "email"
	  } else if (column_type == "PHONE_NUMBER") {
		  return "phone"
	  } else if (column_type == "DUE_DATE") {
		  return "event"
	  } else if (column_type == "START_DATE") {
		  return "event"
	  } else if (column_type == "DESCRIPTION") {
		  return "toc"
	  } else if (column_type == "TITLE") {
		  return "title"
	  } else if (column_type == "STATUS") {
		  return "assignment_turned_in"
	  }

	  
  }


  selectAddViewOption(option) {
	//   if (option.name.toLowerCase() == 'well test') {
	// 	  return 
	//   }
	  this.selectedAddView = option
	  	// @ts-ignore
	this.templatePageTitle = this.selectedAddView.name

	var field_values: any[] = []
	field_values.push({
		title: "Add new field",
		icon_type: "ADD",
		column_type: ""
	})


	for (var x = 0; x < option.groups[0].Columns.length; x++) {
		field_values.push({
			title: option.groups[0].Columns[x].ColumnName,
			icon: this.getColumnTypeIcon(option.groups[0].Columns[x].ColumnType),
			icon_type: option.groups[0].Columns[x].ColumnType == "WELL" ? "WELL" : option.groups[0].Columns[x].ColumnType == "RIG" ? "RIG" : "MATERIAL",
			column_type: option.groups[0].Columns[x].ColumnType,
			custom_name: option.groups[0].Columns[x].ColumnName,
			required: option.groups[0].Columns[x].CoreField != null ? option.groups[0].Columns[x].CoreField : false,
			requiredOnJobCreation: option.groups[0].Columns[x].RequiredOnJobCreation
		})
	}
	  this.templateDetails = [
		  {
			  type: "Fields",
			  // @ts-ignore
			  values: field_values
		  },		  {
			  type: "Rules",
			  // @ts-ignore
			  values: option.rules
		  }
	  ]
  }

  addNewWorkspace() {
	  this.addNewWorkspacePopup = true
  }

}

